import { Height } from '@material-ui/icons';
import Chart from 'chart.js/auto';
import { useEffect, useRef } from 'react';

export default function Graph({ className, maxHeight, type, data, options }) {
  const graphRef = useRef(null);
  let myChart;

  useEffect(() => {
    // Chart.register(...registerables);
    if (graphRef.current) {
      myChart = new Chart(graphRef.current.getContext('2d'), {
        type,
        data,
        options,
      });
    }
    return () => {
      myChart.destroy();
    };
  }, [graphRef.current, type, data, options]);

  return (
    <div className={className || ''}>
      <canvas ref={graphRef} style={maxHeight ? { maxHeight } : null} />
    </div>
  );
}
