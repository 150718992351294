import "./HospitalEdit.css";
import Input from "../UI/input";
import React, { useEffect, useState, useCallback, useContext } from "react";
import LoadingSpinner from "../UI/LoadsingSpinner";
import Error from "../UI/Error";
import instance from "../../config/config";
import useInput from "../hooks/use-Input";
import AuthContext from "../store/authContext";
import Navbar from "../Layouts/Navbar";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";

const isNotEmpty = (value) => {
  return value.trim() !== "";
};

const AclResourceEdit = (props) => {
  let params = useParams();
  let id = params.resource;
  const [loadedsingleResource, setLoadedSingleResource] = useState([]);
  const [error, setError] = useState(null);
  const [loadingSpinner, setloadingSpinner] = useState(false);
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const {
    value: enteredName,
    hasError: nameInputHasError,
    valueCHangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    isValid: enteredNameIsValid,
    reset: resetNameInput,
  } = useInput(isNotEmpty);

  //const id = ;

  const fetchSingleResource = useCallback(() => {
    setError(null);
    setloadingSpinner(true);
    let headers = {
      "x-access-token": authCtx.token,
      "Content-Type": "application/json",
    };
    instance
      .get("acl/resources/" + id, { headers: headers })
      .then((response) => {
        console.log(response);
        setloadingSpinner(false);
        return response.data.data;
      })
      .then((data) => {
        console.log("data", data);
        const singleResource = {
          _id: data._id,
          name: data.name,
        };

        setLoadedSingleResource(singleResource);
      })
      .catch((err) => {
        setloadingSpinner(false);
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.response.data.message || "Something went wrong!");
      });
  }, []);

  useEffect(() => {
    fetchSingleResource(id);
  }, [fetchSingleResource]);

  if (loadingSpinner) {
    return <LoadingSpinner />;
  }

  const submitHandler = (event) => {
    event.preventDefault();

    const id = loadedsingleResource._id;

    const resource = {
      name: enteredName,
    };
    let headers = {
      "x-access-token": authCtx.token,
      "Content-Type": "application/json",
    };

    instance
      .patch("/acl/resources/" + id, { resource }, { headers: headers })
      .then((response) => {
        history.push("/resources");
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.response.data.message || "Something went wrong!");
      });
  };

  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="row gutters">
          {error && <Error err={error} />}
          <form
            onSubmit={submitHandler}
            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
          >
            <div className="card h-100">
              <div className="card-body">
                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h6 className="mb-2 text-primary">ACL Resource Details</h6>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <input type="hidden" value={loadedsingleResource._id} />
                      <Input
                        label="Name"
                        onChange={nameChangeHandler}
                        onBlur={nameBlurHandler}
                        input={{
                          name: "name",
                          type: "text",
                          defaultValue: loadedsingleResource.name,
                        }}
                      />

                      {nameInputHasError && (
                        <p className="text-danger"> Name Cannot Be Empty</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="text-right">
                      <button
                        type="submit"
                        id="submit"
                        name="submit"
                        className="btn btn-primary mt-4"
                      >
                        Update
                      </button>
                      <Link
                        to="/resources"
                        className="btn btn-secondary"
                        style={{ marginLeft: "20px", marginTop: "22px" }}
                      >
                        Back
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AclResourceEdit;
