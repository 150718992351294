import React from "react";
import "./input.css";
const Input = (props) => {
  return (
    <div>
      <label className="mb-1 label-def" htmlFor={props.input.type}>
        {props.label}
      </label>
      <input
        onChange={props.onChange}
        onBlur={props.onBlur}
        className="form-control text-bold input-def"
        {...props.input}
      />
    </div>
  );
};
export default Input;
