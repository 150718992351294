import "./LoginForm.css";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import useInput from "../hooks/use-Input";
import Input from "../UI/input";
import instance from "../../config/config";
import AuthContext from "../store/authContext";
import Error from "../UI/Error";
import jwt_decode from "jwt-decode";
import logo from "../../assets/dailydoclogo.svg";
const isEmail = (value) => {
  var reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (reg.test(value) === false) {
    return false;
  } else {
    return true;
  }
};

const isMobile = (val) => {
  if (/^\d{10}$/.test(val)) {
    return true;
  } else {
    return false;
  }
};

const isNotEmpty = (value) => {
  return value.trim() !== "";
};
const LoginForm = () => {
  const authCtx = useContext(AuthContext);
  const [error, setError] = useState("");
  const [password, setPassword] = useState(false);
  const [showPassword, setShowPassword] = useState("");
  const [verifyForm, showVerifyForm] = useState(false);
  const history = useHistory();
  const [passwordShown, setPasswordShown] = useState(false);

  //OTP VALIDATION
  const {
    value: enteredOtp1,
    hasError: otp1InputHasError,
    valueCHangeHandler: otp1ChangeHandler,
    inputBlurHandler: otp1BlurHandler,
    isValid: enteredOtp1IsValid,
  } = useInput(isNotEmpty);

  const {
    value: enteredOtp2,
    hasError: otp2InputHasError,
    valueCHangeHandler: otp2ChangeHandler,
    inputBlurHandler: otp2BlurHandler,
    isValid: enteredOtp2IsValid,
  } = useInput(isNotEmpty);
  const {
    value: enteredOtp3,
    hasError: otp3InputHasError,
    valueCHangeHandler: otp3ChangeHandler,
    inputBlurHandler: otp3BlurHandler,
    isValid: enteredOtp3IsValid,
  } = useInput(isNotEmpty);
  const {
    value: enteredOtp4,
    hasError: otp4InputHasError,
    valueCHangeHandler: otp4ChangeHandler,
    inputBlurHandler: otp4BlurHandler,
    isValid: enteredOtp4IsValid,
  } = useInput(isNotEmpty);
  const {
    value: enteredOtp5,
    hasError: otp5InputHasError,
    valueCHangeHandler: otp5ChangeHandler,
    inputBlurHandler: otp5BlurHandler,
    isValid: enteredOtp5IsValid,
  } = useInput(isNotEmpty);
  const {
    value: enteredOtp6,
    hasError: otp6InputHasError,
    valueCHangeHandler: otp6ChangeHandler,
    inputBlurHandler: otp6BlurHandler,
    isValid: enteredOtp6IsValid,
  } = useInput(isNotEmpty);

  const {
    value: enteredMobile,
    hasError: mobileInputHasError,
    valueCHangeHandler: mobileChangeHandler,
    inputBlurHandler: mobileBlurHandler,
    isValid: enteredMobileIsValid,
  } = useInput(isMobile);

  const pwdChangeHandler = (event) => {
    setPassword(event.target.value);
  };
  const showPasswordBox = () => {
    setShowPassword(true);
  };

  const otpSendHandler = () => {
    showVerifyForm(true);

    instance
      .post("/auth/login/otp", {
        mobile: enteredMobile,
        countryCode: 91,
        platform: "bd",
      })
      .then((response) => {
        localStorage.setItem("otpId", response.data.data._id);
      })
      .catch((err) => {
        console.log({ err });
        setError(err.response.data.message || "Something went wrong!");
      });
  };

  const handleChange = (e) => {
    console.log("handle change called");
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    let fieldIntIndex = parseInt(fieldIndex, 10);

    // Check if no of char in field == maxlength
    if (value.length >= maxLength) {
      // It should not be last input field
      if (fieldIntIndex < 6) {
        // Get the next input field using it's name
        const nextfield = document.querySelector(
          `input[name=field-${fieldIntIndex + 1}]`
        );

        // If found, focus the next field
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
  };
  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const validateOtp = () => {
    const otp = `
      ${enteredOtp1}${enteredOtp2}${enteredOtp3}${enteredOtp4}${enteredOtp5}${enteredOtp6}`;
    console.log("otp", otp);
    instance
      .post("/auth/bd/verify/otp", {
        _id: localStorage.getItem("otpId"),
        otp: otp,
      })
      .then((response) => {
        authCtx.user(response.data.data.userName, response.data.data.userId);
        authCtx.login(response.data.data.token);
        localStorage.removeItem("otpId");
        history.push("/dashboard");
      })
      .catch((err) => {
        console.log({ err });
        setError(err.response.data.message || "Something went wrong!");
      });
  };
  function changeData1(e) {
    otp1ChangeHandler(e);
    handleChange(e);
  }
  function changeData2(e) {
    otp2ChangeHandler(e);
    handleChange(e);
  }
  function changeData3(e) {
    otp3ChangeHandler(e);
    handleChange(e);
  }
  function changeData4(e) {
    otp4ChangeHandler(e);
    handleChange(e);
  }
  function changeData5(e) {
    otp5ChangeHandler(e);
    handleChange(e);
  }
  function changeData6(e) {
    otp6ChangeHandler(e);
    handleChange(e);
  }

  const formSumbitHandler = (event) => {
    event.preventDefault();
    if (!enteredMobileIsValid) {
      return;
    }

    const bdLogin = {
      mobile: enteredMobile,
      password: password,
      countryCode: 91,
    };
    instance
      .post("/auth/bd/login", { bdLogin })
      .then((response) => {
        console.log(response);

        authCtx.user(response.data.data.userName, response.data.data.userId);
        authCtx.login(response.data.data.token);

        history.push("/dashboard");
      })
      .catch((err) => {
        console.log({ err });
        setError(err.response.data.message || "Something went wrong!");
      });
  };

  return (
    <div className="vh-100 crd">
      {error && <Error err={error} />}
      <div className="container">
        <div className="row m">
          <div className="col-sm-3"></div>

          <div className="col-sm-6">
            <div className="wrapper">
              <div className="text-center">
                <img src={logo} alt="" />
                <span className="comp ms-2">
                  {!verifyForm && <span className="comp">Login</span>}
                  {/* <span className="comp doc ms-1">Doc</span> */}
                </span>
              </div>

              {!verifyForm && (
                <div className="d-flex align-items-center mt-4 ms-xl-4 px-5  mt-2 pt-2 pt-xl-0 mt-xl-n5">
                  <div style={{ width: "23rem" }}>
                    <div class="material-textfield mt-4">
                      <input
                        onChange={mobileChangeHandler}
                        onBlur={mobileBlurHandler}
                        placeholder=" "
                        type="text"
                      />
                      <label>Mobile</label>
                    </div>

                    {mobileInputHasError && (
                      <h6 className="text-danger mt-2 ">
                        Invalid mobile number
                      </h6>
                    )}

                    {showPassword && (
                      <div className="material-textfield mt-5">
                        <input
                          type={passwordShown ? "text" : "password"}
                          placeholder=" "
                          onChange={pwdChangeHandler}
                        />
                        <label for="mobile">Password</label>
                        {passwordShown && (
                          <span
                            toggle="#password-field"
                            onClick={togglePassword}
                            class="fa fa-eye fa-2x field-icon text-muted toggle-password"
                          ></span>
                        )}
                        {!passwordShown && (
                          <span
                            class="fa fa-eye-slash fa-2x text-muted field-icon"
                            onClick={togglePassword}
                          ></span>
                        )}
                      </div>
                    )}
                    {!showPassword && (
                      <button
                        className="btnn btn text-light mt-5 w-100"
                        disabled={!enteredMobileIsValid}
                        onClick={showPasswordBox}
                      >
                        Proceed
                      </button>
                    )}

                    {showPassword && (
                      <div className="pt-1 text-center mt-4">
                        <button
                          className="btnn btn text-light w-100"
                          onClick={formSumbitHandler}
                        >
                          Login
                        </button>
                      </div>
                    )}

                    {showPassword && (
                      <div className="text-center">
                        <h5>or</h5>
                        <h5
                          className="font-weight-bold cursor text"
                          onClick={otpSendHandler}
                        >
                          Login with OTP
                        </h5>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {verifyForm && (
                <div class="d-flex justify-content-center align-items-center container">
                  <div class="mt-5 px-3">
                    <h5 class="m-0 fw-bold">Mobile phone verification</h5>
                    <span class="mobile-text">
                      Enter the code we just send on your mobile phone 
                      <b>{enteredMobile}</b>
                    </span>
                    <div class="d-flex flex-row mt-4">
                      <input
                        onChange={changeData1}
                        onBlur={otp1BlurHandler}
                        type="text"
                        maxlength="1"
                        minlength="1"
                        name="field-1"
                        className="form-control otp-mar"
                        autofocus=""
                      />
                      <input
                        onChange={changeData2}
                        name="field-2"
                        onBlur={otp2BlurHandler}
                        type="text"
                        maxlength="1"
                        minlength="1"
                        className="form-control otp-mar"
                      />
                      <input
                        onChange={changeData3}
                        onBlur={otp3BlurHandler}
                        name="field-3"
                        type="text"
                        maxlength="1"
                        minlength="1"
                        className="form-control otp-mar"
                      />
                      <input
                        onChange={changeData4}
                        onBlur={otp4BlurHandler}
                        type="text"
                        name="field-4"
                        maxlength="1"
                        minlength="1"
                        className="form-control otp-mar"
                      />
                      <input
                        onChange={changeData5}
                        onBlur={otp5BlurHandler}
                        name="field-5"
                        type="text"
                        maxlength="1"
                        minlength="1"
                        className="form-control otp-mar"
                      />
                      <input
                        onChange={changeData6}
                        onBlur={otp6BlurHandler}
                        type="text"
                        name="field-6"
                        maxlength="1"
                        minlength="1"
                        className="form-control otp-mar"
                      />
                    </div>
                    <div class="text-center mt-5">
                      <span class="d-block mobile-text">
                        Don't receive the code?
                      </span>
                      <span class="font-weight-bold text cursor">Resend</span>
                    </div>
                    <div className="text-center">
                      <button
                        onClick={validateOtp}
                        disabled={
                          !enteredOtp1IsValid ||
                          !enteredOtp2IsValid ||
                          !enteredOtp3IsValid ||
                          !enteredOtp4IsValid ||
                          !enteredOtp5IsValid ||
                          !enteredOtp6IsValid
                        }
                        className="btnn btn text-light mt-3 w-75"
                      >
                        Validate
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
