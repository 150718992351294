import React, { useState, useContext } from "react";
import LoadingSpinner from "../UI/LoadsingSpinner";
import Error from "../UI/Error";
import Navbar from "../Layouts/Navbar";
import "./PasswordReset.css";
import useInput from "../hooks/use-Input";
import instance from "../../config/config";
import AuthContext from "../store/authContext";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
const isNotEmpty = (value) => {
  return value.trim() !== "";
};
export default function PasswordReset() {
  const [error, setError] = useState(null);
  const [loadingSpinner, setloadingSpinner] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [oldPassword, setOldPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const {
    value: enteredOldPassword,
    hasError: oldPasswordInputHasError,
    valueCHangeHandler: oldPasswordChangeHandler,
    inputBlurHandler: oldPasswordBlurHandler,
    isValid: enteredOldPassword1IsValid,
  } = useInput(isNotEmpty);
  const {
    value: enteredPassword1,
    hasError: password1InputHasError,
    valueCHangeHandler: password1ChangeHandler,
    inputBlurHandler: password1BlurHandler,
    isValid: enteredPassword1IsValid,
  } = useInput(isNotEmpty);
  const {
    value: enteredPassword2,
    hasError: password2InputHasError,
    valueCHangeHandler: password2ChangeHandler,
    inputBlurHandler: password2BlurHandler,
    isValid: enteredPassword2IsValid,
  } = useInput(isNotEmpty);
  const showPasswordBox = () => {
    setPasswordShown(!passwordShown);
  };

  const showPasswordBox1 = () => {
    setPasswordShown1(!passwordShown1);
  };

  const showOldPassword = () => {
    setOldPassword(!oldPassword);
  };

  const submitHandler = () => {
    if (enteredPassword1 != enteredPassword2) {
      setPasswordError(true);
      return;
    }

    const managerData = {
      changePassword: true,
      oldPassword: enteredOldPassword,
      password: enteredPassword1,
      confirmPassword: enteredPassword2,
    };
    console.log(managerData);
    const updateManagerHandler = async () => {
      let headers = {
        "x-access-token": authCtx.token,
        "Content-Type": "application/json",
      };
      instance
        .patch(
          "/bd/bdManagers/" + localStorage.getItem("userId"),
          { managerData },
          { headers: headers }
        )
        .then((response) => {
          swal({
            title: "Password changed successfully!",
            icon: "success",
            button: "Aww yiss!",
          }).then(() => {
            history.push("/profile");
          });
        })
        .catch((err) => {
          if (err.response.status == 401) {
            authCtx.logout();
          }
          setError(err.response.data.message || "Something went wrong!");
        });
    };
    updateManagerHandler();
  };

  return (
    <>
      <Navbar />
      <div className="container d-flex justify-content-center">
        <div className="row mt-3">
          {/* { <LoadingSpinner />} */}
          <div className="col">
            {error && <Error err={error} />}
            <div class="css-zdqc84">
              <div className="css-1mafxii mt-4">Reset Password</div>
              <div className="d-flex align-items-center  ms-xl-4 px-5 pt-xl-0">
                <div style={{ width: "28rem" }}>
                  <div className="material-textfield mt-3">
                    <input
                      onChange={oldPasswordChangeHandler}
                      onBlur={oldPasswordBlurHandler}
                      type={oldPassword ? "text" : "password"}
                      placeholder=" "
                    />
                    <label for="mobile">Old Password</label>

                    {oldPassword && (
                      <span
                        onClick={showOldPassword}
                        toggle="#password-field"
                        class="fa fa-eye fa-2x field-icon text-muted toggle-password"
                      ></span>
                    )}

                    {!oldPassword && (
                      <span
                        onClick={showOldPassword}
                        class="fa fa-eye-slash fa-2x text-muted field-icon"
                      ></span>
                    )}
                  </div>
                  <div className="material-textfield mt-5">
                    <input
                      onChange={password1ChangeHandler}
                      onBlur={password1BlurHandler}
                      type={passwordShown1 ? "text" : "password"}
                      placeholder=" "
                    />
                    <label for="mobile">New Password</label>

                    {passwordShown1 && (
                      <span
                        onClick={showPasswordBox1}
                        toggle="#password-field"
                        class="fa fa-eye fa-2x field-icon text-muted toggle-password"
                      ></span>
                    )}

                    {!passwordShown1 && (
                      <span
                        onClick={showPasswordBox1}
                        class="fa fa-eye-slash fa-2x text-muted field-icon"
                      ></span>
                    )}
                  </div>

                  {/*                    
                      <h6 className="text-danger mt-2 ">
                        Invalid mobile number
                      </h6> */}

                  <div className="material-textfield mt-5">
                    <input
                      onChange={password2ChangeHandler}
                      onBlur={password2BlurHandler}
                      type={passwordShown ? "text" : "password"}
                      placeholder=" "
                    />
                    <label for="mobile">Retype Password</label>

                    {passwordShown && (
                      <span
                        onClick={showPasswordBox}
                        toggle="#password-field"
                        class="fa fa-eye fa-2x field-icon text-muted toggle-password"
                      ></span>
                    )}

                    {!passwordShown && (
                      <span
                        onClick={showPasswordBox}
                        class="fa fa-eye-slash fa-2x text-muted field-icon"
                      ></span>
                    )}
                  </div>
                  {passwordError && (
                    <div className="mt-1 text-danger">
                      Password does not match
                    </div>
                  )}
                  <div className="pt-1 text-center mt-4">
                    <button
                      onClick={submitHandler}
                      disabled={
                        !enteredPassword1IsValid || !enteredPassword2IsValid
                      }
                      className="css-1oqm2v0 mb-5"
                    >
                      Reset My Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
