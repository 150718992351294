import React, { useEffect, useState, useContext } from 'react';
import MaterialTable from 'material-table';
import instance from '../../config/config';
//import {columns} from './static/hospitalColumn'
import AuthContext from '../store/authContext';
import { Link } from 'react-router-dom';
import _, { reverse } from 'lodash';
import Navbar from '../Layouts/Navbar';
function HospitalAdmin() {
  const authCtx = useContext(AuthContext);
  const [hospitalAdmins, setHospitalAdmins] = useState([]);
  const [error, setError] = useState(null);

  const fetchHospitalAdmins = async () => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    let response = await instance.get('/bd/primaryHospitals', {
      headers: headers,
    });
    setHospitalAdmins(response.data.data);
    console.log(response.data);
  };

  useEffect(() => {
    fetchHospitalAdmins();
  }, []);

  const columns = [
    {
      title: 'First Name',
      field: 'userDetails.firstName',
    },
    {
      title: 'Last Name',
      field: 'userDetails.lastName',
    },
    {
      title: 'Country code',
      field: 'userDetails.countryCode',
    },
    {
      title: 'Mobile',
      field: 'userDetails.mobile',
    },
    {
      title: 'Email',
      field: 'userDetails.email',
    },
    {
      title: 'Hospital Count',
      field: 'hospitalDetails.length',
    },

    {
      title: 'Action',
      render: (rowData) => (
        <div>
          <Link
            to={`/primaryHospitals/${rowData.userDetails._id}`}
            className="btn btn-primary me-1"
          >
            <i className="fa fa-eye" aria-hidden="true"></i>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <Navbar />
      <div className="px-4">
        <div>
          <h2 className=" mt-2">Hospital Admins</h2>
        </div>
        <div>{error ? error : ''}</div>
        <MaterialTable
          title="Hospital Admins"
          style={{
            marginTop: '2rem',
            // boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
            borderRadius: '10px',
          }}
          options={{ exportButton: true }}
          data={hospitalAdmins}
          columns={columns}
        ></MaterialTable>
      </div>
    </>
  );
}

export default HospitalAdmin;
