const Checked = (props) => {
  if (props.status) {
    return (
      <div class="form-check">
        <input
          className="form-check-input ms-2"
          type="checkbox"
          value=""
          onClick={props.onClick}
          id="flexCheckChecked"
          checked
        />
      </div>
    );
  } else {
    return (
      <div className="form-check">
        <input
          className="form-check-input ms-2 "
          type="checkbox"
          onClick={props.onClick}
          value=""
          id="flexCheckChecked"
        />
      </div>
    );
  }
};

export default Checked;
