import instance from "../../config/config";
import React, { useCallback, useEffect, useState, useContext } from "react";
import AuthContext from "../store/authContext";
import useInput from "../hooks/use-Input";
import Error from "../UI/Error";
import Input from "../UI/input";
const isNotEmpty = (value) => {
  return value.trim() !== "";
};

const isEmail = (value) => {
  var reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (reg.test(value) === false) {
    return false;
  } else {
    return true;
  }
};
const isMobile = (val) => {
  if (/^\d{10}$/.test(val)) {
    return true;
  } else {
    return false;
  }
};

const UpdateManagerForm = (props) => {
  const [loadingSpinner, setloadingSpinner] = useState(false);
  const [loadedManager, setloadedManager] = useState("");
  const [status, setStatus] = useState();
  const [error, setError] = useState("");
  const authCtx = useContext(AuthContext);

  const {
    value: enteredName,
    hasError: nameInputHasError,
    valueCHangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    isValid: enteredNameIsValid,
    reset: resetNameInput,
  } = useInput(isNotEmpty);

  const {
    value: enteredEmail,
    hasError: emailInputHasError,
    valueCHangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    isValid: enteredEmailIsValid,
    reset: resetEmailInput,
  } = useInput(isEmail);

  const {
    value: enteredMobile,
    hasError: mobileInputHasError,
    valueCHangeHandler: mobileChangeHandler,
    inputBlurHandler: mobileBlurHandler,
    isValid: enteredMobileIsValid,
    reset: resetMobileInput,
  } = useInput(isMobile);

  const {
    value: enteredPassword,
    hasError: passwordInputHasError,
    valueCHangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    isValid: enteredPasswordIsValid,
    reset: resetPasswordInput,
  } = useInput(isNotEmpty);

  const id = props.id;
  const fetchSingleManager = useCallback(() => {
    setError(null);
    setloadingSpinner(true);
    let headers = {
      "x-access-token": authCtx.token,
      "Content-Type": "application/json",
    };
    instance
      .get("/bd/bdManagers/" + id, { headers: headers })
      .then((response) => {
        setloadingSpinner(false);
        console.log(response);
        return response.data.data;
      })
      .then((data) => {
        setloadedManager(data);
      })
      .catch((err) => {
        setloadingSpinner(false);
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.response.data.message || "Something went wrong!");
      });
  }, []);

  useEffect(() => {
    fetchSingleManager(id);
  }, [fetchSingleManager]);
  const statusManageHandler = (act) => {
    console.log(act);
    setStatus(!act);
  };
  const style = (errorState) => {
    if (errorState) {
      return "form-group invalid";
    } else {
      return "form-group";
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const managerId = loadedManager._id;
    const managerData = {
      name: enteredName,
      email: enteredEmail,
      password: enteredPassword,
      mobile: enteredMobile,
      active: status,
    };
    props.updateManager(managerData, managerId);
  };

  return (
    <form
      onSubmit={submitHandler}
      className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
    >
      {error && <Error err={error} />}
      <div className="card h-100">
        <div className="card-body">
          <div className="row gutters">
            <div className="col-xl-12 col-lg-12 mb-3col-md-12 col-sm-12 col-12">
              <h5 className="mb-2 text-dark border-bottom">Update Manager</h5>
            </div>

            <div className="col-xl-12 p-2 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className={style(nameInputHasError)}>
                <Input
                  onChange={nameChangeHandler}
                  onBlur={nameBlurHandler}
                  label="Manager Name"
                  input={{
                    type: "text",

                    defaultValue: loadedManager.name,
                  }}
                />
                {nameInputHasError && (
                  <p className="text-danger"> Name Cannot Be Empty</p>
                )}
              </div>
            </div>
            <div className="col-xl-12 p-2 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className={style(emailInputHasError)}>
                <Input
                  onChange={emailChangeHandler}
                  onBlur={emailBlurHandler}
                  label="Manager Email"
                  input={{
                    type: "text",
                    defaultValue: loadedManager.email,
                  }}
                />
                {emailInputHasError && (
                  <p className="text-danger"> Email is Not Valid</p>
                )}
              </div>
            </div>
            <div className="col-xl-12 p-2 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className={style(passwordInputHasError)}>
                <Input
                  onChange={passwordChangeHandler}
                  onBlur={passwordBlurHandler}
                  label="Manager Password"
                  input={{
                    type: "password",
                  }}
                />
                {passwordInputHasError && (
                  <p className="text-danger"> Password cannot Be Empty</p>
                )}
              </div>
            </div>
            <div className="col-xl-12 p-2 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className={style(mobileInputHasError)}>
                <Input
                  onChange={mobileChangeHandler}
                  onBlur={mobileBlurHandler}
                  label="Manager Mobile"
                  input={{
                    type: "tel",
                    defaultValue: loadedManager.mobile,
                  }}
                />
                {mobileInputHasError && (
                  <p className="text-danger"> Invalid Mobile Number</p>
                )}
              </div>
            </div>

            <div className="col-xl-12 p-2 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="form-group">
                <label htmlFor="status">Status</label>
                {loadedManager.active === false && (
                  <div className="form-check form-switch">
                    <input
                      onClick={statusManageHandler.bind(
                        this,
                        loadedManager.active
                      )}
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                    />
                    <span className="py-0 px-1 bg-danger rounded text-white">
                      Inactive
                    </span>
                  </div>
                )}
                {loadedManager.active === true && (
                  <div className="form-check form-switch">
                    <input
                      onClick={statusManageHandler.bind(
                        this,
                        loadedManager.active
                      )}
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      checked
                    />
                    <span className="py-0 px-1 bg-success rounded text-white">
                      Active
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className=" d-flex justify-content-end">
                  <button
                    type="submit"
                    id="submit"
                    name="submit"
                    className="btn btn-primary mt-2"
                  >
                    Update Manager
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export default UpdateManagerForm;
