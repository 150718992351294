import React from 'react';
import Navbar from '../Layouts/Navbar';
import { Link, useHistory } from 'react-router-dom';
import Table from '../Table/Table';

const Hospitaldetails = () => {
  const history = useHistory();
  const hospitalAddHandler = () => {
    history.push('/add-hospital');
  };
  return (
    <>
      <Navbar />
      {/* <button
        className="button-3 mt-3"
        style={{ marginLeft: "53rem" }}
        onClick={hospitalAddHandler}
      >
        Add Hospital <i className="fa fa-chevron-right" aria-hidden="true"></i>
      </button> */}
      <h2 className="px-4 mt-2">Hospitals</h2>
      <Table />
    </>
  );
};

export default Hospitaldetails;
