import React, { useState, Fragment, useContext } from "react";
import Input from "../UI/input";
import useInput from "../hooks/use-Input";
import Navbar from "../Layouts/Navbar";
import AuthContext from "../store/authContext";
import { useHistory } from "react-router-dom";
import instance from "../../config/config";
import Error from "../UI/Error";

const isNotEmpty = (value) => {
  return value.trim() !== "";
};

function AddHospitalWard() {
  const authCtx = useContext(AuthContext);
  let history = useHistory();
  const [error, setError] = useState("");
  const token = authCtx.token;
  const {
    value: enteredName,
    hasError: nameInputHasError,
    valueCHangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    isValid: enteredNameIsValid,
    reset: resetNameInput,
  } = useInput(isNotEmpty);

  const {
    value: enteredshortName,
    hasError: shortNameInputHasError,
    valueCHangeHandler: shortNameChangeHandler,
    inputBlurHandler: shortNameBlurHandler,
    isValid: enteredshortNameIsValid,
    reset: resetshortNameInput,
  } = useInput(isNotEmpty);

  let formIsValid = false;
  if (enteredshortNameIsValid && enteredNameIsValid) {
    formIsValid = true;
  }

  const submitHandler = (event) => {
    event.preventDefault();
    if (!enteredNameIsValid && !enteredshortNameIsValid) {
      return;
    }

    const ward = {
      name: enteredName,
      shortName: enteredshortName,
    };
    let headers = {
      "x-access-token": token,
      "Content-Type": "application/json",
    };
    instance
      .post("/templates/ward", { ward }, { headers: headers })
      .then((response) => {
        history.push("/hospitalWard");
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.response.data.message || "Something went wrong!");
      });
  };
  const style = (errorState) => {
    if (errorState) {
      return "form-group invalid";
    } else {
      return "form-group";
    }
  };
  return (
    <>
      <Navbar />
      <div>{error ? <Error err={error} /> : ""}</div>
      <form
        onSubmit={submitHandler}
        className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
      >
        <h2 className="card-header text-center text-light bg-dark rounded-top">
          Add Hospital Ward
        </h2>
        <div className="card h-100">
          <div className="card-body">
            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h6 className="mb-2 text-primary">Personal Details</h6>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className={style(nameInputHasError)}>
                  <Input
                    onChange={nameChangeHandler}
                    onBlur={nameBlurHandler}
                    label="Name"
                    input={{
                      type: "text",
                    }}
                  />
                  {nameInputHasError && (
                    <p className="text-danger"> Name is Not Valid</p>
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className={style(shortNameInputHasError)}>
                  <Input
                    onChange={shortNameChangeHandler}
                    onBlur={shortNameBlurHandler}
                    label="Short Name"
                    input={{
                      type: "text",
                    }}
                  />
                  {shortNameInputHasError && (
                    <p className="text-danger"> Allter Name is Not Valid</p>
                  )}
                </div>
              </div>
              <div className="row gutters">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="text-right">
                    <button
                      type="submit"
                      id="submit"
                      name="submit"
                      className="btn btn-primary mt-4"
                      disabled={!formIsValid}
                    >
                      Add Ward
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddHospitalWard;
