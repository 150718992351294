import axios from "axios";
import { useContext } from "react";
import AuthContext from "../components/store/authContext";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_MY_API_URL}`,
  // headers: {
  //   "x-access-token": authToken,
  //   "Content-Type": "application/json",
  // },
});

export default instance;
