import React, { useEffect, useState, useCallback, useContext } from 'react';
import Error from '../UI/Error';
import { useHistory, Link } from 'react-router-dom';
import instance from '../../config/config';
import OfToggleButton from '../UI/OfToggle';
import MaterialTable from 'material-table';
import { reverse } from 'lodash';
import OnToggleButton from '../UI/OnToggle';
import AuthContext from '../store/authContext';
import './Table.css';
const Table = () => {
  const [loadedHospitals, setHospitalData] = useState([]);
  const [error, setError] = useState(null);

  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const fetchSeHospitals = useCallback(async () => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get('/hospitals', { headers: headers })
      .then((response) => {
        console.log({ response });

        setHospitalData(response.data.data);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          authCtx.logout();
        }
        setError(err.message);
      });
  }, []);

  useEffect(() => {
    fetchSeHospitals();
  }, [fetchSeHospitals]);

  const profileHandler = (id) => {
    history.push({ pathname: '/hospital/' + id });
  };

  const profileEditHandler = (id) => {
    history.push('/hospitals/' + id);
  };

  const statusChangeHandler = (status, hospitalid) => {
    const hospital = {
      active: !status,
      _id: hospitalid,
    };
    //  setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };

    instance
      .patch('/hospitals/' + hospitalid, { hospital }, { headers: headers })
      .then((response) => {
        // history.push("/hospital/" + hospitalid);
        fetchSeHospitals();
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Country',
      field: 'country',
    },
    {
      title: 'State',
      field: 'state',
    },
    {
      title: 'City',
      field: 'city',
    },

    {
      title: 'Registration Date',
      field: 'createdAt',
      type: 'date',
    },
    {
      title: 'Status',
      field: 'active',
      type: 'boolean',
      render: (rowData) => (
        <div>
          {rowData.active && (
            <span className=" badge badge-success">active</span>
          )}
          {!rowData.active && (
            <span className="badge badge-danger">Inactive</span>
          )}

          {rowData.active && (
            <OnToggleButton
              onClick={statusChangeHandler.bind(
                this,
                rowData.active,
                rowData._id
              )}
            />
          )}
          {!rowData.active && (
            <OfToggleButton
              onClick={statusChangeHandler.bind(
                this,
                rowData.active,
                rowData._id
              )}
            />
          )}
        </div>
      ),
    },

    {
      title: 'Actions',
      render: (rowData) => (
        <div>
          <Link
            to={`/hospital/${rowData._id}`}
            className="btn btn-primary me-1"
          >
            <i className="fa fa-eye" aria-hidden="true"></i>
          </Link>
          <Link
            to={`/hospitals/${rowData._id}`}
            className="btn btn-success me-1"
          >
            <i className="fa fa-edit " aria-hidden="true"></i>
          </Link>
          <Link to={`/hospitals/${rowData._id}`} className="btn btn-danger">
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Link>
        </div>
      ),
    },
  ];

  const hospitalAddHandler = () => {
    history.push('/add-hospital');
  };
  // let tableContent = loadedHospitals.map((hospitals) => {
  //   const date = hospitals.createdAt;
  //   const dateObj = new Date(date);
  //   const fullDate = dateObj.toDateString();

  //   return (
  //     <tr role="button" key={hospitals._id}>
  //       <td onClick={profileHandler.bind(this, hospitals._id)}>
  //         {hospitals.name}
  //       </td>
  //       <td>{hospitals.city}</td>
  //       <td>{fullDate}</td>

  //       {hospitals.active === true && (
  //         <td>
  //           <span className="py-0 px-1 bg-success rounded text-white">
  //             active
  //           </span>
  //           <OnToggleButton
  //             status={hospitals.active}
  //             onClick={statusChangeHandler.bind(
  //               this,
  //               hospitals.active,
  //               hospitals._id,
  //               hospitals.name,
  //               hospitals.email
  //             )}
  //           />
  //         </td>
  //       )}
  //       {hospitals.active === false && (
  //         <td>
  //           <span className="py-0 px-1 bg-danger rounded text-white">
  //             inactive
  //           </span>
  //           <OnToggleButton
  //             status={hospitals.active}
  //             onClick={statusChangeHandler.bind(
  //               this,
  //               hospitals.active,
  //               hospitals._id
  //             )}
  //           />
  //         </td>
  //       )}

  //       <td>
  //         <button
  //           onClick={profileEditHandler.bind(this, hospitals._id)}
  //           className="btn btn-primary"
  //         >
  //           Edit
  //         </button>
  //       </td>
  //     </tr>
  //   );
  // });

  return (
    // <div className="row">
    <div className="col-md-6 col-xl-12 px-3 ">
      <MaterialTable
        title="Hospitals"
        style={{
          marginTop: '2rem',
          // boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
          borderRadius: '10px',
        }}
        data={reverse(loadedHospitals)}
        columns={columns}
        options={{
          headerStyle: { color: 'black', fontSize: '15px' },
          // exportButton: true,
          // grouping: true,
          pageSize: 4,
          //rowStyle: { backgroundColor: 'green'},
          rowStyle: (rowData) => {
            if (!rowData.active) {
              return { backgroundColor: '#BFBFBF' };
            }

            return {};
          },
        }}
      ></MaterialTable>
    </div>
    // </div>
  );
};

export default Table;
