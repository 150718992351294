import React, { useContext, useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import instance from '../../config/config';
import AuthContext from '../store/authContext';
import { Link } from 'react-router-dom';
import _, { reverse } from 'lodash';
import Error from '../UI/Error';
import Navbar from '../Layouts/Navbar';

function HospitalWard() {
  const authCtx = useContext(AuthContext);
  const [hospitalWard, setHospitalWard] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchHospitalWard();
  }, []);

  const fetchHospitalWard = async () => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    let response = await instance.get('/templates/wards', { headers: headers });
    setHospitalWard(reverse(response.data.data));
    //console.log(response.data)
  };

  const handleDelete = async (id) => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    let response = await instance.delete(`/templates/wards/${id}`, {
      headers: headers,
    });

    fetchHospitalWard();
  };

  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Short Name',
      field: 'shortName',
    },
    {
      title: 'Ward Code',
      field: 'code',
    },
    {
      title: 'Action',
      render: (rowData) => (
        <div>
          <Link
            to={`/hospitalWard/${rowData._id}`}
            className="btn btn-success me-3"
          >
            <i className="fa fa-edit"></i>
          </Link>
          <button
            onClick={() => handleDelete(rowData._id)}
            className="btn btn-danger"
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];
  return (
    <>
      <Navbar />
      <div className="px-4">
        <div>
          <Link
            className="button-3 mt-3"
            style={{ marginLeft: '53rem' }}
            to="/add-hospitalWard"
          >
            Add Ward
          </Link>
          <div>{error ? <Error err={error} /> : ''}</div>
          <MaterialTable
            title="Hospital Ward"
            options={{ grouping: true, export: true }}
            style={{
              marginTop: '2rem',
              // boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
              borderRadius: '10px',
            }}
            data={hospitalWard}
            columns={columns}
          ></MaterialTable>
        </div>
      </div>
    </>
  );
}

export default HospitalWard;
