import React, { useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './sideBar.css';
import AuthContext from '../store/authContext';
import logo from '../../assets/dailydoclogo.svg';
import Navbar from './Navbar';
const MainNavigation = () => {
  return (
    <React.Fragment>
      <div className="wid">
        <section className="section-content">
          <div className="row">
            <aside className="col-lg-3">
              <nav className="sidebar bg-dark py-2 mb-4 cc">
                <ul className="nav flex-column " id="nav_accordion">
                  <li className="nav-item ">
                    <header className=" sidebarlogo bg-dark px-4 text-white ">
                      <span>
                        <img src={logo} alt="" />
                      </span>
                      <span className="blockquote ms-3">Daily Doc</span>
                    </header>
                  </li>
                  <li className="nav-item mt-4">
                    <NavLink
                      className="text-dark h5 nav-link text-white"
                      to="/dashboard"
                    >
                      <i
                        className="fa fa-tachometer ms-2 me-2 text-white"
                        aria-hidden="true"
                      ></i>
                      Dashboard
                    </NavLink>
                  </li>

                  <li class="nav-item">
                    <NavLink
                      className="text-dark h5 nav-link text-white"
                      to="/hospital-admins"
                    >
                      <i
                        className="fa fa-info-circle ms-2 me-2"
                        aria-hidden="true"
                      ></i>
                      Admins
                    </NavLink>
                  </li>

                  <li class="nav-item">
                    <NavLink
                      className="text-dark h5 nav-link text-white"
                      to="/queries"
                    >
                      <i
                        className="fa fa-info-circle ms-2 me-2"
                        aria-hidden="true"
                      ></i>
                      Queries
                    </NavLink>
                  </li>

                  <li className="nav-item border-none">
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item ">
                        <h5 className="accordion-header" id="flush-headingOne">
                          <button
                            className="accordion-button collapsed  bg-dark"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            <h6 className="ms-4 h5 text-white">ACL</h6>
                          </button>
                        </h5>
                        <div
                          id="flush-collapseOne"
                          className="accordion-collapse collapse bg-dark"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <NavLink
                              className="text-dark h5 nav-link text-white"
                              to="/roles"
                            >
                              <i className="fa fa-users" aria-hidden="true"></i>{' '}
                              ACL/Roles
                            </NavLink>
                            <NavLink
                              className="text-dark h5 nav-link text-white"
                              to="/resources"
                            >
                              <i
                                className="fa fa-plus-square"
                                aria-hidden="true"
                              ></i>{' '}
                              Resources
                            </NavLink>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item text-white">
                        <h5 className="accordion-header " id="flush-headingTwo">
                          <button
                            className="accordion-button collapsed bg-dark text-white"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            <h6 className="ms-4 h5">Hospital</h6>
                          </button>
                        </h5>
                        <div
                          id="flush-collapseTwo"
                          className="accordion-collapse collapse bg-dark text-white"
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <NavLink
                              className="text-dark h5 nav-link text-white"
                              to="/hospitals"
                            >
                              <i
                                className="fa fa-hospital-o me-1"
                                aria-hidden="true"
                              ></i>{' '}
                              Hospitals
                            </NavLink>
                            <NavLink
                              className="text-dark h5 nav-link text-white"
                              to="/hospitalRole"
                            >
                              <i
                                className="fa fa-hospital-o me-1"
                                aria-hidden="true"
                              ></i>
                              Roles
                            </NavLink>
                            <NavLink
                              className="text-dark h5 nav-link text-white"
                              to="/hospitalDesignation"
                            >
                              <i
                                className="fa fa-hospital-o me-1"
                                aria-hidden="true"
                              ></i>
                              Designations
                            </NavLink>
                            <NavLink
                              className="text-dark h5 nav-link text-white"
                              to="/hospitalDepartment"
                            >
                              <i
                                className="fa fa-hospital-o me-1"
                                aria-hidden="true"
                              ></i>
                              Departments
                            </NavLink>
                            <NavLink
                              className="text-dark h5 nav-link text-white"
                              to="/hospitalWard"
                            >
                              <i
                                className="fa fa-hospital-o me-1 text-white"
                                aria-hidden="true"
                              ></i>
                              Wards
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </aside>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default MainNavigation;
