import React, { useContext, useEffect, useState, useCallback } from 'react';
import MaterialTable from 'material-table';
import instance from '../../config/config';
import AuthContext from '../store/authContext';
import LoadingSpinner from '../UI/LoadsingSpinner';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import _, { reverse } from 'lodash';
import Error from '../UI/Error';
import Navbar from '../Layouts/Navbar';
function Queries() {
  const [queryData, setQueryData] = useState([]);
  const [error, setError] = useState(null);
  const [loadingSpinner, setloadingSpinner] = useState(false);

  const authCtx = useContext(AuthContext);

  // const userId = authCtx.userId;

  const fetchQueriesData = useCallback(() => {
    setError(null);
    // setloadingSpinner(true);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get('/query', { headers: headers })
      .then((response) => {
        setloadingSpinner(false);
        return response.data.data;
      })
      .then((data) => {
        console.log(data);
        setQueryData(data);
      })
      .catch((err) => {
        setloadingSpinner(false);
        setError(err.message || 'Something went wrong!');
      });
  }, []);

  useEffect(() => {
    fetchQueriesData();
  }, [fetchQueriesData]);

  const handleDelete = async (id) => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    console.log(id);

    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this data!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let response = instance
          .delete(`/query/contacts/${id}`, {
            headers: headers,
          })
          .then((res) => {
            fetchQueriesData();
            swal('Your data has been deleted!', {
              icon: 'success',
            });
          })
          .catch((err) => {
            setError(err.message || 'Something went wrong!');
          });
      } else {
        // swal("Your data is safe!");
      }
    });
  };

  const handleUpdate = async (id) => {
    setError(null);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    const userId = localStorage.getItem('userId');
    swal({
      title: 'Are you sure you contacted this user?',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        instance
          .patch(
            `/query/contacts/${id}`,
            { userId },
            {
              headers: headers,
            }
          )
          .then((res) => {
            console.log(res);
            fetchQueriesData();
          })
          .catch((err) => {
            setError(err.message || 'Something went wrong!');
          });
      } else {
        // swal("Your data is safe!");
      }
    });
  };

  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'Company',
      field: 'companyName',
    },
    {
      title: 'Message',
      field: 'message',
    },
    {
      title: 'Mobile',
      field: 'mobile',
    },
    {
      title: 'Status',
      field: 'contacted',
      type: 'boolean',

      render: (rowData) => (
        <div>
          {rowData.contacted && (
            <span className="badge badge-success">Done</span>
          )}
          {!rowData.contacted && (
            <span className="badge badge-danger">Pending</span>
          )}
        </div>
      ),
    },

    // {
    //   title: "Action",
    //   render: (rowData) => (
    //     <Link
    //       to={`/hospitalDesignation/${rowData._id}`}
    //       className="btn btn-info"
    //     >
    //       Edit
    //     </Link>
    //   ),
    // },
    {
      title: 'Actions',
      render: (rowData) => (
        <div>
          <button
            onClick={() => handleUpdate(rowData._id)}
            className="btn btn-success me-2"
          >
            <i class="fa fa-check" aria-hidden="true"></i>
          </button>
          <button
            onClick={() => handleDelete(rowData._id)}
            className="btn btn-danger"
          >
            <i className="fa  fa-trash "></i>
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Navbar />
      {/* <Link
        className="btn btn-info"
        style={{ marginLeft: "53rem" }}
        to="/add-hospitalDesignation"
      >
        Add Designation
      </Link> */}
      <h2 className="px-4 mt-2">Contacts</h2>
      {error && <Error err={error} />}
      <div className="px-4">
        <MaterialTable
          title="Daily doc contacts"
          style={{
            marginTop: '2rem',
            // boxShadow: 'rgb(0 0 0 / 4%) 0px 10px 10px 4px',
            borderRadius: '10px',
          }}
          data={reverse(queryData)}
          options={{
            headerStyle: { color: 'black', fontSize: '15px' },
            exportButton: true,
            grouping: true,
          }}
          columns={columns}
        ></MaterialTable>
      </div>
    </div>
  );
}

export default Queries;
