import './HospitalEdit.css';
import Input from '../UI/input';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import LoadingSpinner from '../UI/LoadsingSpinner';
import Error from '../UI/Error';
import instance from '../../config/config';
import useInput from '../hooks/use-Input';
import AuthContext from '../store/authContext';
import Navbar from '../Layouts/Navbar';
import { useParams, useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const isNotEmpty = (value) => {
  return value.trim() !== '';
};

const HospitalEdit = (props) => {
  let params = useParams();
  let id = params._id;
  const [loadedsingleHospitalDesignation, setLoadedSingleHospitalDesignation] =
    useState([]);
  const [error, setError] = useState(null);
  const [loadingSpinner, setloadingSpinner] = useState(false);
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const {
    value: enteredName,
    hasError: nameInputHasError,
    valueCHangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    isValid: enteredNameIsValid,
    reset: resetNameInput,
  } = useInput(isNotEmpty);

  const {
    value: enteredaltName,
    hasError: altNameInputHasError,
    valueCHangeHandler: altNameChangeHandler,
    inputBlurHandler: altNameBlurHandler,
    isValid: enteredaltNameIsValid,
    reset: resetaltNameInput,
  } = useInput(isNotEmpty);

  //const id = ;

  const fetchSingleHospitalDesignation = useCallback(() => {
    setError(null);
    setloadingSpinner(true);
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };
    instance
      .get('/templates/designations/' + id, { headers: headers })
      .then((response) => {
        setloadingSpinner(false);
        return response.data.data;
      })
      .then((data) => {
        const singleHospitalDesignation = {
          _id: data._id,
          name: data.name,
          altName: data.altName,
        };

        setLoadedSingleHospitalDesignation(singleHospitalDesignation);
      })
      .catch((err) => {
        setloadingSpinner(false);
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.response.data.message || 'Something went wrong!');
      });
  }, []);

  useEffect(() => {
    fetchSingleHospitalDesignation(id);
  }, [fetchSingleHospitalDesignation]);

  if (loadingSpinner) {
    return <LoadingSpinner />;
  }

  const submitHandler = (event) => {
    event.preventDefault();

    if (!enteredNameIsValid && !enteredaltNameIsValid) {
      return;
    }

    const id = loadedsingleHospitalDesignation._id;

    const hospitalDesignation = {
      name: enteredName,
      altName: enteredaltName,
    };
    let headers = {
      'x-access-token': authCtx.token,
      'Content-Type': 'application/json',
    };

    instance
      .patch(
        '/templates/designations/' + id,
        { hospitalDesignation },
        { headers: headers }
      )
      .then((response) => {
        history.push('/hospitalDesignation');
      })
      .catch((err) => {
        if (err.response.status == 401) {
          authCtx.logout();
        }
        setError(err.response.data.message || 'Something went wrong!');
      });
  };
  console.log(loadedsingleHospitalDesignation.name);
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="row gutters">
          {props.error && <Error err={props.error} />}
          <form
            onSubmit={submitHandler}
            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
          >
            <div className="card h-100">
              <div className="card-body">
                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h6 className="mb-2 text-primary">Personal Details</h6>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="hidden"
                        value={loadedsingleHospitalDesignation._id}
                      />
                      <Input
                        label="Name"
                        onChange={nameChangeHandler}
                        onBlur={nameBlurHandler}
                        input={{
                          name: 'name',
                          type: 'text',
                          defaultValue: loadedsingleHospitalDesignation.name,
                        }}
                      />

                      {nameInputHasError && (
                        <p className="text-danger"> Name Cannot Be Empty</p>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <Input
                        label="Allter Name"
                        onChange={altNameChangeHandler}
                        onBlur={altNameBlurHandler}
                        input={{
                          type: 'text',
                          name: 'altName',
                          defaultValue: loadedsingleHospitalDesignation.altName,
                        }}
                      />

                      {altNameInputHasError && (
                        <p className="text-danger"> Invalid Allter Name</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="text-right">
                      <button
                        type="submit"
                        id="submit"
                        name="submit"
                        className="btn btn-primary mt-4"
                      >
                        Update
                      </button>
                      <Link
                        to="/hospitalDesignation"
                        className="btn btn-secondary"
                        style={{ marginLeft: '20px', marginTop: '22px' }}
                      >
                        Back
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HospitalEdit;
