import React, { useState, useContext } from "react";
import { useHistory } from "react-router";
import useInput from "../hooks/use-Input";
import Input from "../UI/input";
import instance from "../../config/config";
import AuthContext from "../store/authContext";
import Error from "../UI/Error";
import Navbar from "../Layouts/Navbar";

const isNotEmpty = (value) => {
  return value.trim() !== "";
};

export default function PasswordOtp() {
  const authCtx = useContext(AuthContext);
  const [error, setError] = useState("");
  const history = useHistory();

  //OTP VALIDATION
  const {
    value: enteredOtp1,
    hasError: otp1InputHasError,
    valueCHangeHandler: otp1ChangeHandler,
    inputBlurHandler: otp1BlurHandler,
    isValid: enteredOtp1IsValid,
  } = useInput(isNotEmpty);

  const {
    value: enteredOtp2,
    hasError: otp2InputHasError,
    valueCHangeHandler: otp2ChangeHandler,
    inputBlurHandler: otp2BlurHandler,
    isValid: enteredOtp2IsValid,
  } = useInput(isNotEmpty);
  const {
    value: enteredOtp3,
    hasError: otp3InputHasError,
    valueCHangeHandler: otp3ChangeHandler,
    inputBlurHandler: otp3BlurHandler,
    isValid: enteredOtp3IsValid,
  } = useInput(isNotEmpty);
  const {
    value: enteredOtp4,
    hasError: otp4InputHasError,
    valueCHangeHandler: otp4ChangeHandler,
    inputBlurHandler: otp4BlurHandler,
    isValid: enteredOtp4IsValid,
  } = useInput(isNotEmpty);
  const {
    value: enteredOtp5,
    hasError: otp5InputHasError,
    valueCHangeHandler: otp5ChangeHandler,
    inputBlurHandler: otp5BlurHandler,
    isValid: enteredOtp5IsValid,
  } = useInput(isNotEmpty);
  const {
    value: enteredOtp6,
    hasError: otp6InputHasError,
    valueCHangeHandler: otp6ChangeHandler,
    inputBlurHandler: otp6BlurHandler,
    isValid: enteredOtp6IsValid,
  } = useInput(isNotEmpty);
  const handleChange = (e) => {
    console.log("handle change called");
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    let fieldIntIndex = parseInt(fieldIndex, 10);

    // Check if no of char in field == maxlength
    if (value.length >= maxLength) {
      // It should not be last input field
      if (fieldIntIndex < 6) {
        // Get the next input field using it's name
        const nextfield = document.querySelector(
          `input[name=field-${fieldIntIndex + 1}]`
        );

        // If found, focus the next field
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
  };

  const validateOtpHandler = () => {
    const otp = `
      ${enteredOtp1}${enteredOtp2}${enteredOtp3}${enteredOtp4}${enteredOtp5}${enteredOtp6}`;
    console.log("otp", otp);
    instance
      .post("/auth/bd/verify/otp", {
        _id: localStorage.getItem("id"),
        otp: otp,
      })
      .then((response) => {
        console.log(response);
        localStorage.removeItem("otp_Id");
        // history.push("/dashboard");
      })
      .catch((err) => {
        console.log({ err });
        setError(err.response.data.message || "Something went wrong!");
      });
  };
  function changeData1(e) {
    otp1ChangeHandler(e);
    handleChange(e);
  }
  function changeData2(e) {
    otp2ChangeHandler(e);
    handleChange(e);
  }
  function changeData3(e) {
    otp3ChangeHandler(e);
    handleChange(e);
  }
  function changeData4(e) {
    otp4ChangeHandler(e);
    handleChange(e);
  }
  function changeData5(e) {
    otp5ChangeHandler(e);
    handleChange(e);
  }
  function changeData6(e) {
    otp6ChangeHandler(e);
    handleChange(e);
  }

  return (
    <>
      <Navbar />
      <div class="d-flex justify-content-center align-items-center container">
        <div className="wrapper">
          <div class="px-3">
            <h5 class="m-0 ">User verification</h5>
            <span class="mobile-text">
              Enter the code we just send on your mobile phone 
              {/* <b class="text">{enteredMobile}</b> */}
            </span>
            <div class="d-flex flex-row mt-5">
              <input
                onChange={changeData1}
                onBlur={otp1BlurHandler}
                type="text"
                maxlength="1"
                minlength="1"
                name="field-1"
                className="form-control otp-mar"
                autofocus=""
              />
              <input
                onChange={changeData2}
                name="field-2"
                onBlur={otp2BlurHandler}
                type="text"
                maxlength="1"
                minlength="1"
                className="form-control otp-mar"
              />
              <input
                onChange={changeData3}
                onBlur={otp3BlurHandler}
                name="field-3"
                type="text"
                maxlength="1"
                minlength="1"
                className="form-control otp-mar"
              />
              <input
                onChange={changeData4}
                onBlur={otp4BlurHandler}
                type="text"
                name="field-4"
                maxlength="1"
                minlength="1"
                className="form-control otp-mar"
              />
              <input
                onChange={changeData5}
                onBlur={otp5BlurHandler}
                name="field-5"
                type="text"
                maxlength="1"
                minlength="1"
                className="form-control otp-mar"
              />
              <input
                onChange={changeData6}
                onBlur={otp6BlurHandler}
                type="text"
                name="field-6"
                maxlength="1"
                minlength="1"
                className="form-control otp-mar"
              />
            </div>
            <div class="text-center mt-5">
              <span class="d-block mobile-text">Don't receive the code?</span>
              <span class="font-weight-bold text cursor">Resend</span>
            </div>
            <div className="text-center">
              <button
                onClick={validateOtpHandler}
                disabled={
                  !enteredOtp1IsValid ||
                  !enteredOtp2IsValid ||
                  !enteredOtp3IsValid ||
                  !enteredOtp4IsValid ||
                  !enteredOtp5IsValid ||
                  !enteredOtp6IsValid
                }
                className="btnn btn text-light mt-3 w-75"
              >
                Validate
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
