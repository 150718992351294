import React, { Component } from 'react';

const Check = () => {
  return (
    <div className="wid">
      <section className="section-content">
        <div className="row">
          <aside className="col-lg-3">
            <nav className="sidebar  py-2 mb-4 cc">
              <ul className="nav flex-column" id="nav_accordion">
                <li className="nav-item">
                  <a className="nav-link text-dark" href="#">
                    {' '}
                    Link name{' '}
                  </a>
                </li>
                <li className="nav-item">
                  <div
                    class="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 class="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          ACL
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <a href="">wasim</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-dark" href="#">
                    {' '}
                    Another page{' '}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-dark" href="#">
                    {' '}
                    Demo link{' '}
                  </a>
                </li>
              </ul>
            </nav>
          </aside>
        </div>
      </section>
    </div>
  );
};

export default Check;
